.organization__area {
  &--statistics {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0 0 16px 0;

    @media (max-width: 580px) {
      flex-wrap: wrap;
      gap: 8px;
      padding: 0;
    }

    &--item {
      flex-grow: 1;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        background-image: linear-gradient(to right, $c-second, $c-bitcion) ;
        padding: 16px;
        min-height: 60px;
        border-radius: 8px;
        border: 0.5px solid rgba($c-white, 1);

        .left {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 8px;
          color: $c-white;
          font-size: clamp(14px, 4vw, 16px);
        }
        .right {
          color: $c-main;
          font-size: clamp(16px, 3vw, 24px);
          font-weight: 700;
          sub,span {
            font-weight: 400;
            font-size: clamp(14px, 3vw, 16px);
            color: $c-white;
          }
        }
      }
    }
  }
}
