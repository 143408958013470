.description--wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  @media (max-width: 991px) {
    height: auto;
  }

  &--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 991px) {
      flex-direction: row;
      align-items: center;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .label {
      font-size: clamp(12px, 1vw, 14px);
      color: $c-gray;
      font-weight: 400;
    }

    .value {
      font-size: clamp(14px, 2vw, 16px);
      font-weight: 700;
      padding-left: 4px;
    }
  }

  .green {
    color: $c-green-2;
  }

  .red {
    color: $c-red;
  }

  .white {
    color: $c-white;
  }
}

.market--wrap {
  background-color: $c-dark;
  height: 60px;
  width: "100%";
  border: 1px solid $c-blue-light;
  border-radius: 8px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $c-white;
  cursor: pointer;

  @media (max-width: 991px) {
    margin-top: 24px;
  }

  .label {
    font-size: clamp(16px, 3vw, 20px);
    font-weight: 400;
  }

  .icon {
    margin-right: 16px;
    display: flex;

    i {
    }

    img {
      height: 32px;
    }
  }

  &:hover {
    background-color: $c-third;
    border: 1px solid $c-forth;
  }
}

.position--input {
  margin: 16px 0;

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: clamp(16px, 3vw, 14px);
      color: $c-white;

      small {
        font-size: clamp(16px, 3vw, 14px);
        color: $c-gray;
        padding-left: 8px;
      }
    }
  }

  &--values {
    margin-top: 8px;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid $c-blue-dark2;
    background-color: $c-blue-dark3;

    .item {
      padding: 0 16px;
    }

    .divider {
      margin: 8px 0;
      width: 100%;
      border: 0.5px solid $c-blue-light;
    }
  }
}

.position--search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.list--wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 500px) {
      display: none;
    }

    @media (max-width: 1550px) {
      display: none;
    }

    span {
      min-width: 80px;
      font-size: 14px;
      color: $c-gray;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
      justify-content: flex-end !important;
    }
  }

  &--items {
    min-height: 260px;
    overflow-y: auto;
    height: 300px;
    padding-top: 8px;
    width: 100%;

    @media (max-width: 790px) {
      margin-bottom: 80px;
    }

    @media (max-width: 500px) {
      overflow-y: initial;
      height: auto;
    }

    @media (max-width: 1550px) {
      overflow-y: initial;
      height: auto;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border: 0px none #ffffff;
    }

    &::-webkit-scrollbar-thumb:active {
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
    }

    &::-webkit-scrollbar-track:active {
      background: transparent;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  &--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $c-dark-2;
    border-radius: 4px;
    padding: 4px 4px;
    font-size: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    gap: 8px;

    @media (min-width: 1550px) {
      height: 50px;
    }

    @media (max-width: 500px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    @media (max-width: 1550px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &--block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 500px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        white-space: nowrap;
        width: 45%;
        padding-bottom: 8px;
      }

      @media (max-width: 1550px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        white-space: nowrap;
        width: 45%;
        padding-bottom: 8px;
      }

      p {
        margin-bottom: 0;
        color: $c-gray-light;
      }
    }

    .type {
      font-size: 16px;
      font-weight: 500;
      justify-content: flex-start;

      .short {
        color: $c-red !important;
      }

      .long {
        color: $c-green-2 !important;
      }
    }

    .leverage {
      justify-content: flex-start;
    }

    .size {
      font-size: 16px;
      font-weight: 500;
      color: $c-white !important;
      justify-content: flex-start;
    }

    .long-percent {
      @media (max-width: 1550px) {
        font-size: $font14;
      }

      font-size: $font12;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .positive {
        color: $c-green-2 !important;
      }

      .negative {
        color: $c-red !important;
      }
    }

    .status {
      font-size: 16px;
      font-weight: 500;
      color: $c-white !important;
      justify-content: flex-start;
      text-transform: capitalize;
    }

    .action {
      justify-content: flex-end;

      svg {
        cursor: pointer;
      }
    }

    &:hover {
      background-color: $c-third;
    }
  }

  .label--mobile {
    display: none;

    @media (max-width: 500px) {
      display: block;
      font-weight: 400;
      color: $c-gray;
    }

    @media (max-width: 1550px) {
      display: block;
      font-weight: 400;
      color: $c-gray;
    }
  }
}

.col-30 {
  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 1550px) {
    width: 100%;
  }
}

//
.summary__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    padding: 16px;
    background-color: $c-background;
    border-radius: 8px;

    span {
      color: $c-dark;
    }

    p {
      color: $c-main;
      font-weight: 500;
    }
  }
}
