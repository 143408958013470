.radio-group {
  &__content {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: 8px;
    }

    &--label {
      font-weight: 600;
      flex-direction: column;
    }
  }

  &__radio {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex !important;
    // height: 60px !important;

    :where(
        .css-dev-only-do-not-override-12upa3x
      ).ant-radio-button-wrapper:hover {
      color: $c-second;
    }

    &--radio {
      // border-radius: 4px !important;
      // background: $c-white !important;
      // border-color: $c-white !important;

      > label {
        display: flex !important;
        text-align: center !important;
        background: $c-white !important;
        flex: 1 1 0% !important;
        align-items: center !important;
        justify-content: center !important;
        height: 100% !important;
        border-radius: 4px !important;
        border: 1px solid $c-gray-light !important;
        padding: 16px 0;

        color: $c-text;
        font-weight: 500;
        font-size: clamp(14px, 3vw, 14px) !important;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          border: none;
        }

        &:not(.ant-radio-button-wrapper-checked) {
          border: 1px solid $c-gray-light !important;
        }

        &.ant-radio-button-wrapper-checked {
          background: rgba($c-second, 0.06) !important;
          color: black !important;
          border-radius: 4px !important;
          border-color: $c-second !important;
          border: 1px solid $c-second !important;
          color: $c-second !important;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          &:hover::before,
          &:before {
            background-color: transparent;
          }
        }
      }
    }

    &--error {
      font-weight: 500;
      color: red;
    }
  }
}

.group--horizental {
  .radio-group__radio .radio-group__radio--radio {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .radio-group__radio--radio > label {
    margin-bottom: 0;
    padding: 4px 16px;
    text-align: left !important;
    justify-content: flex-start !important;
    border-radius: 6px !important;

    &::before {
      display: none !important;
    }

    &:hover,
    &.ant-radio-button-wrapper-checked {
      background: rgba($c-white, 1) !important;
      color: black !important;
      border-color: $c-second !important;
      border: 1px solid $c-second !important;
      color: $c-second !important;
    }
  }
}
