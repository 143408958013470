.layout {
  background-color: $c-background;
  color: $c-gray;
}

.children--layout {
  padding-bottom: 40px;
  // flex-grow: 1;
  // padding-left: 260px;

  @media (max-width: 991px) {
    // padding-left: 0;
  }
}

// .container {
//   position: relative;
//   margin-left: auto;
//   margin-right: auto;
//   padding-right: 15px;
//   padding-left: 15px;

//   @media (min-width: 476px) {
//     padding-right: 15px;
//     padding-left: 15px;
//   }

//   @media (min-width: 768px) {
//     padding-right: 15px;
//     padding-left: 15px;
//   }

//   @media (min-width: 992px) {
//     padding-right: 15px;
//     padding-left: 15px;
//   }

//   @media (min-width: 1200px) {
//     padding-right: 15px;
//     padding-left: 15px;
//   }

//   @media (min-width: 476px) {
//     width: 100%;
//   }

//   @media (min-width: 992px) {
//     padding-right: 15px;
//     padding-left: 15px;
//     max-width: 100%;
//   }

//   @media (min-width: 1200px) {
//     padding-right: 24px;
//     padding-left: 24px;
//     max-width: 100%;
//   }

//   @media (min-width: 1400px) {
//     padding-right: 40px;
//     padding-left: 40px;
//     max-width: 100%;
//   }
// }


.container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1499px) {
    max-width: 1320px;
    margin: 0 auto;
  }

  @media (max-width: 1399px) {
    max-width: 100%;
    padding: 0 64px;
  }

  @media (max-width: 1299px) {
    max-width: 1140px;
    padding: 0 32px;
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 576px) {
    max-width: 100%;
    padding: 0 16px;
  }

  @media (max-width: 400px) {
    max-width: 100%;
    padding: 0 16px;
  }
}