.box--wrap {
  border: 1px solid $c-gray-light;
  background-color: $c-white;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  z-index: 0;
  height: 465px !important;
}

.box--blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 8px;
  height: 465px !important;
  z-index: 1;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba($c-dark-2, 0.5);
}

// Boxes - Modifier

.boxes--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.box--item {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $c-gray-light;
  background-color: $c-white;
  min-height: 250px;
  width: 48%;

  @media (max-width: 700px) {
    width: 100%;
  }

  h3 {
    font-size: clamp(16px, 3vw, 24px);
    color: $c-forth;
    margin-bottom: 8px;
    font-weight: 600;
  }

  .input--item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;

    label {
      color: #e5e8ed;
      font-size: clamp(14px, 3vw, 16px);
      margin-bottom: 8px;
    }

    .ant-picker {
      width: 100%;
      height: 40px;
      background-color: #151a26;
      border-radius: 8px;
      border: none;
      padding: 0 16px;
      color: $c-second;

      input {
        color: $c-second;
        font-size: clamp(14px, 3vw, 18px);
      }

      .ant-picker-suffix {
        color: #28375a;
        font-size: 16px;
      }
    }

    .ant-input {
      width: 100%;
      height: 40px;
      background-color: #151a26;
      border-radius: 8px;
      border: none;
      padding: 0 16px;
      color: $c-second;
      font-size: clamp(14px, 3vw, 18px);
    }
  }

  .text {
    p {
      color: #e5e8ed;
      font-size: clamp(14px, 3vw, 16px);
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Card

.card__simple {
  background-color: $c-white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: rgba(223, 225, 226, 0.2) 0px 0px 8px;

  &--head {
    padding-bottom: 24px;

    h3 {
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 600;
      color: $c-black;
    }

    p {
      font-size: clamp(12px, 3vw, 14px);
      margin-bottom: 8px;
      color: $c-text;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.card__product1 {
  height: 280px;
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;

  @media (max-width: 991px) {
    height: 220px;
  }

  img {
    aspect-ratio: 2/1;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    transition: all ease-in 0.4s;
    object-fit: cover;
  }

  &--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-image: linear-gradient(to top, $c-main 20%, transparent);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    transition: all ease-in 0.4s;

    h3 {
      color: $c-second;
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 600;
      margin-bottom: 8px;
    }

    p {
      color: $c-gray-light;
      font-size: clamp(12px, 3vw, 14px);
      font-weight: 400;
      margin-bottom: 0;
      line-height: 20px;
    }

    &:hover {
      background-color: rgba($c-main, 0.2);
      transition: all ease-in 0.4s;
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
      transition: all ease-in 0.4s;
    }
  }
}

.card__product {
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background-color: $c-white;

  img {
    aspect-ratio: 1/1;
    height: 50px;
    width: max-content;
    border-radius: 8px;
    transition: all ease-in 0.4s;
    object-fit: cover;
  }

  &--content {
    h3 {
      color: $c-black;
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 600;
      margin-bottom: 16px;
    }

    p {
      color: $c-text;
      font-size: clamp(12px, 3vw, 14px);
      font-weight: 400;
      margin-bottom: 0;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &:hover {
      transition: all ease-in 0.4s;
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
      transition: all ease-in 0.4s;
    }
  }
}

.card__product--small {
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: $c-white;
  border: 1px solid transparent;
  transition: all ease-in 0.4s;
  cursor: pointer;

  img {
    aspect-ratio: 1/1;
    height: 24px;
    width: max-content;
    border-radius: 8px;
    transition: all ease-in 0.4s;
    object-fit: cover;
  }

  .content {
    h3 {
      color: $c-black;
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 400;
      margin-bottom: 0;
    }

    &:hover {
      transition: all ease-in 0.4s;
    }
  }

  &:hover {
    border: 1px solid rgba($c-second, 0.5);
    transition: all ease-in 0.4s;

    img {
      transform: scale(1.1);
      transition: all ease-in 0.4s;
    }
  }
}

.card__quick {
  background-color: $c-white;
  border-radius: 8px;
  border-bottom: 2px solid #f4f7ff;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: all ease-in 0.4s;

  &--content {
    width: 90%;

    h3 {
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 600;
      color: $c-black;
      margin-bottom: 16px;
    }

    p {
      font-size: clamp(12px, 3vw, 14px);
      color: $c-text;
      margin-bottom: 0;
    }
  }

  .action {
    height: 32px;
    width: 32px;
    border-radius: 100px;
    border: 1px solid $c-gray-light;
    color: $c-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in 0.4s;
  }

  &:hover {
    border-bottom-color: rgba($c-second, 0.5);
    transition: all ease-in 0.4s;

    .action {
      transform: scale(1.1);
      border-color: $c-second;
      background-color: $c-second;
      color: $c-white;
      transition: all ease-in 0.4s;
    }
  }
}

//
.list__area {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapper {
    background-color: $c-white;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  &--item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
    min-width: 15%;

    small {
      font-size: 10px;
      font-weight: 300;
    }

    span {
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 600;
      color: $c-dark;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: max-content;

    .btn--show {
      cursor: pointer;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: $c-background;
    }
  }

  .status {
    padding: 4px 8px;
    width: max-content;
    border-radius: 4px;
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;

    &.active {
      background-color: $c-green;
      color: $c-white;
    }

    &.pending {
      background-color: $c-second;
      color: $c-white;
    }
  }
}

.pagination__area {
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ant-pagination .ant-pagination-item a {
    color: $c-main;
  }

  .ant-pagination-item-active a {
    color: $c-second;
  }

  .ant-pagination-item-active:hover a {
    color: $c-second;
  }
}

//
.center__area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 80vh;

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;

    svg {
      color: $c-second;
    }

    h3 {
      color: $c-main;
      font-size: clamp(18px, 3vw, 24px);
      line-height: clamp(24px, 3vw, 32px);
    }

    p {
      color: $c-text;
    }
  }
}

.address__wrapper {
  margin-top: 24px;
  width: 100%;
  .item {
    width: 100%;
    word-break: break-all;
    background-color: rgba($c-second, 0.06);
    border: 1px solid rgba($c-second, 0.2);
    padding: 8px;
    border-radius: 8px;
    color: $c-second;
  }
}

//
.products__area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
  grid-gap: 16px;

  .item {
    background-color: $c-white;
    border-radius: 8px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(209, 209, 209, 0.2) 0px 0px 4px 0px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 8px;
      span {
        font-size: 12px;
        font-weight: 400;
      }

      .action {
        cursor: pointer;
      }
    }
    .type {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 32px;
      img {
        height: 20px;
        object-fit: contain;
      }
      span {
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 500;
        color: $c-dark;
      }
    }
    .name {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h4 {
        font-size: clamp(18px, 3vw, 22px);
        font-weight: 400;
        color: $c-dark;
      }

      .status {
        color: $c-green;
        display: flex;
        align-items: center;
      }
    }
  }

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
    background-color: #f0f2fa;
    border: 1px solid #d5daec;
    border-radius: 8px;
    span {
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 500;
      color: $c-dark;
    }
  }
}
