.custom--tab {
  @media (max-width: 991px) {
    margin-top: 24px;
  }

  .ant-tabs-tab {
    color: $c-gray-light;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: clamp(14px, 3vw, 16px) !important;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: $c-forth;
    }
  }

  .ant-tabs-ink-bar {
    background: $c-forth;
    border-radius: 2px;
  }

  .ant-tabs-nav-list {
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tabpane {
    min-height: 200px;
  }
}

.ant-tabs-nav::before {
  border-bottom: $c-white !important;
}


.tab__area {

  &--menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid rgba($c-gray, .3);


    li {
      a {
        text-decoration: none;

        .menu--item {
          font-size: clamp(14px, 3vw, 14px);
          color: $c-text;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          border-bottom: 2px solid transparent;
          padding: 0 16px;
          padding-bottom: 12px;
          transition: all ease-in .4s;
          margin-bottom: -2px;

          &:hover {
            color: $c-second;
            border-bottom: 2px solid rgba($c-second, 1);
            transition: all ease-in .4s;
          }
        }

      }
    }

    .active {
      .menu--item {
        border-bottom: 2px solid rgba($c-second, 1);
        transition: all ease-in .4s;
        color: $c-second;
      }
    }
  }
}