.row__area {
  display: flex;
  flex-wrap: wrap;
}

// Col Style here :

[class*="col--"] {
  position: relative;
  width: 100%;
}

/* Column setup for XS devices */
.col--xs-1 {
  flex: 0 0 8.33%;
  max-width: 8.33%;
}
.col--xs-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.col--xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col--xs-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.col--xs-5 {
  flex: 0 0 41.66%;
  max-width: 41.66%;
}
.col--xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col--xs-7 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
}
.col--xs-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}
.col--xs-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col--xs-10 {
  flex: 0 0 83.33%;
  max-width: 83.33%;
}
.col--xs-11 {
  flex: 0 0 91.66%;
  max-width: 91.66%;
}
.col--xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .col--sm-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col--sm-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col--sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col--sm-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col--sm-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col--sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col--sm-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col--sm-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col--sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col--sm-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col--sm-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col--sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .col--md-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col--md-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col--md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col--md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col--md-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col--md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col--md-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col--md-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col--md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col--md-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col--md-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col--md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .col--lg-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col--lg-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col--lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col--lg-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col--lg-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col--lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col--lg-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col--lg-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col--lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col--lg-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col--lg-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col--lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col--xl-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col--xl-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col--xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col--xl-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col--xl-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col--xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col--xl-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col--xl-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col--xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col--xl-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col--xl-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col--xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Gutter styles */
.gutter--4 {
  gap: 4px;
}
.gutter--8 {
  gap: 8px;
}
.gutter--16 {
  gap: 16px;
}
.gutter--24 {
  gap: 24px;
}
.gutter--32 {
  gap: 32px;
}
.gutter--40 {
  gap: 40px;
}
