.header__area {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: $c-white;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8px;

  box-shadow: rgba(219, 221, 224, 0.2) 0px 4px 24px;

  .top--header {
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &--top {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid rgba($c-gray, 0.2);
  }

  &--bottom {
    width: 100%;
    padding: 12px 0 0 0;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &--left {
    display: flex;
    align-items: center;
    gap: 16px;

    .logo {
      img {
        height: 30px;

        @media (max-width: 420px) {
          height: 24px;
        }

        @media (max-width: 375px) {
          height: 20px;
        }
      }
    }

    .toggle {
      display: none;

      @media (max-width: 991px) {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($c-main, 0.02);
        cursor: pointer;
        border-radius: 4px;
        height: 40px;
        width: 40px;
      }
    }

    &--menu {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 16px;
      align-items: center;
      list-style: none;
      padding-left: 40px;
      margin-bottom: 0;

      li {
        text-align: center;
        position: relative;

        i,
        svg {
          display: none;
        }

        .link,
        a {
          text-decoration: none;
          width: 100%;
          line-height: 32px;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          color: $c-dark;

          &:hover {
            text-decoration: none;
          }
        }

        .active,
        &:hover {
          a {
            color: rgba($c-second, 1);
          }
        }
      }
    }

    @media screen and (max-width: 1084px) {
      &--menu {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: $c-white;
        width: 100%;
        padding: 0.5rem 1rem;
        display: flex;
        align-content: center;
        justify-content: space-between;
        border-radius: 1.25rem 1.25rem 0 0;
        transition: 0.4s;

        border-top: 1px solid rgba($c-gray, 0.1);
        z-index: 9;

        svg,
        i {
          display: block !important;
          font-size: clamp(14px, 3vw, 18px);
        }

        li {
          a {
            display: flex;
            align-items: center;
            flex-direction: column;
            line-height: 30px;
            font-size: clamp(14px, 3vw, 18px);
          }
        }
      }
    }
  }

  &--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      list-style: none;
      padding: 0;

      li {
        border-radius: 4px;
        background-color: $c-background;

        a {
          text-decoration: none;
          height: 32px;
          min-width: 32px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          color: $c-text;
        }
      }
    }

    @media (max-width: 991px) {
      gap: 8px;
    }
  }

  .nav--menu {
    padding: 0;
    display: flex;
    align-items: flex-start;
    list-style: none;
    gap: 56px;

    li {
      width: 100%;
      position: relative;

      a {
        text-decoration: none;

        .menu--item {
          width: 100%;
          font-size: clamp(14px, 3vw, 14px);
          color: $c-text;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          border-bottom: 2px solid transparent;
          padding: 0;
          padding-bottom: 12px;
          transition: all ease-in 0.4s;

          &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 24px;
            height: 3px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: $c-second;
            transition: all ease-in 0.4s;
            transform: scaleX(0);
            transform-origin: left;
          }
          img {
            height: 20px;
            width: 20px;
            opacity: 1;
          }

          &:hover {
            color: $c-second;
            transition: all ease-in 0.4s;

            &::after {
              transform: scaleX(1);
            }

            img {
              opacity: 1;
              filter: brightness(0) saturate(100%) invert(56%) sepia(99%)
                saturate(822%) hue-rotate(349deg) brightness(100%) contrast(94%);
            }
          }
        }
      }
    }

    .active {
      .menu--item {
        color: $c-second;
        transition: all ease-in 0.4s;
        &::after {
          transform: scaleX(1);
        }

        img {
          opacity: 1;
          filter: brightness(0) saturate(100%) invert(56%) sepia(99%)
            saturate(822%) hue-rotate(349deg) brightness(100%) contrast(94%);
        }
      }
    }
  }

  .mode--action {
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: $c-text;
      font-size: clamp(14px, 3vw, 14px);
    }
  }
}

// Switch
:where(
    .css-dev-only-do-not-override-12upa3x
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled),
.ant-switch.ant-switch-checked {
  background-color: rgba($c-second, 1);
}
