.slider {
  width: 100%;
  .slider--boundaries {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 600;
    font-size: $font16;
  }
  .ant-slider-dot {
    border-color: $c-blue-light;
    background-color: $c-blue-light;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: $c-blue-light;
  }
  .ant-slider-rail {
    background-color: $c-blue-light;
  }
  .ant-slider-track {
    background-color: $c-main !important;
  }

  .ant-slider-handle.ant-tooltip-open {
    border-color: $c-purple;
    background-color: $c-purple;
  }

  .ant-slider-dot-active {
    border-color: $c-purple;
    background-color: $c-main;
  }
}

.custom--progress {
  .ant-progress-inner {
    background-color: $c-black;
  }
  .ant-progress-bg {
    background-color: $c-forth;
  }
}
