//
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "../components/header";

:root {}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: 'M PLUS Rounded 1c', sans-serif;
}

#root {}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 10px;
  margin-inline-end: 0;
}

body {
  background-color: $c-background;
  color: $c-gray;
  // font-family: "M PLUS Rounded 1c", sans-serif;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

::-moz-selection {
  /* Code for Firefox */
  color: $c-white;
  background: $c-main;
}

::selection {
  color: $c-white;
  background: $c-main;
}

.center--content {
  min-height: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center--content--col {
  width: 60%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 32px;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 991px) {
    width: 90%;
  }
}