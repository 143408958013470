.dashboard__area {
  // height: calc(100vh - 70px);
  margin-top: 64px !important;
  width: 60%;
  margin: 0 auto;

  @media (max-width: 1199px) {
    width: 70%;
  }

  @media (max-width: 991px) {
    width: 80%;
    margin-top: 24px !important;
    padding-bottom: 80px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  h1 {
    font-size: clamp(24px, 3vw, 36px);
    color: $c-second;
    font-weight: 700;
    padding-bottom: 16px;
    margin: 0 0;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 0;
  }

  &--content {
    background-color: #04081a;
    border-radius: 16px;
    padding: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $c-blue-light;
    // align-items: ;

    order: 1;

    @media (max-width: 767px) {
      order: 2;
      text-align: center;
    }

    .item {
      text-align: center;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 32px;

      @media (max-width: 991px) {
        width: 100%;
        flex-wrap: wrap;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .switch {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 32px;
      position: relative;
      z-index: 0;

      &::after {
        content: "";
        background-color: $c-blue-light;
        height: 2px;
        width: 95%;
        border-radius: 2px;
        position: absolute;
        z-index: -1;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &--action {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;

    p {
      font-size: clamp(12px, 3vw, 20px);
      color: $c-white;
      margin-bottom: 32px;
    }
  }
}

.infos--header {
  margin: 16px 0;

  .ant-row {
    align-items: center;
    justify-content: flex-end;
  }
}

.position--slider {
  .slider--boundaries {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 600;
    font-size: $font16;
  }
  .ant-slider-dot {
    border-color: $c-blue-light;
    background-color: $c-blue-light;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: $c-blue-light;
  }
  .ant-slider-rail {
    background-color: $c-blue-light;
  }
  .ant-slider-track {
    background-color: $c-main !important;
  }

  .ant-slider-handle.ant-tooltip-open {
    border-color: $c-purple;
    background-color: $c-purple;
  }

  .ant-slider-dot-active {
    border-color: $c-purple;
    background-color: $c-main;
  }
}

.positions--selectors__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  .buttons--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: 1199px) {
      gap: 8px;
    }
  }
  .selected {
    background-color: $c-main;
    color: $c-white;
  }
  button {
    height: 45px;
    width: 45px;
    border: 1px solid $c-gray-light;
    background-color: $c-background;
    color: $c-text;
    border-radius: 8px;

    @media (max-width: 1024px) {
      width: 40px !important;
    }
    @media (max-width: 400px) {
      width: 40px !important;
    }
    @media (max-width: 360px) {
      height: 50px;
    }
  }
  .input--transparent {
    width: 25%;
    background-color: $c-dark;
    padding: 8px 16px;
    border-radius: 8px;
    height: 45px;
    @media (max-width: 1199px) {
      width: 20%;
    }
    @media (max-width: 991px) {
      width: 20%;
    }
    @media (max-width: 400px) {
      width: 40px !important;
    }
    @media (max-width: 360px) {
      width: 30%;
      height: 50px;
    }
  }
}

.positions--slider {
  text-align: right;
  text-transform: capitalize;
  height: 100px;

  .blocks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  // @media (max-width: 1594px) {
  //   height: 140px;
  // }
  // @media (max-width: 991px) {
  //   height: 100px;
  // }

  .button--submit {
    margin-bottom: 4px;
  }
}

.positions--fee {
  text-align: right;
  text-transform: capitalize;
  margin-block: 12px;

  .blocks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  // @media (max-width: 1594px) {
  //   height: 140px;
  // }
  // @media (max-width: 991px) {
  //   height: 100px;
  // }

  .button--submit {
    margin-bottom: 4px;
  }
}
