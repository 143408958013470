.alert--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 4px;

  .icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .text {
    font-size: clamp(12px, 3vw, 16px);
    color: $c-white;
  }
}

.alert--color--danger {
  background-color: $c-red-linear;
}
.alert--color--success {
  background-color: $c-green-linear;
}
.alert--color--info {
  background-color: $c-forth;
}
.alert--color--warning {
  background-color: $c-orange;
}

// Notification
.ant-notification {
  color: $c-gray-light;
  .ant-notification-notice {
    background-color: $c-main;
    background-image: linear-gradient(to left, $c-third, $c-main);
    border-radius: 8px;

    .ant-notification-notice-message {
      h3.title {
        color: $c-white;
        font-weight: 600;
        font-size: clamp(16px, 3vw, 18px);
      }
    }
    .ant-notification-notice-description {
      .description {
        color: $c-gray-light;
        p {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: $c-second !important;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}
