.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.supporting--modal {
  width: 50%;

  @media (max-width: 991px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
}

.custom--modal {
  .ant-modal-content {
    background-color: $c-dark-2;
    border-radius: 8px;
  }

  .ant-modal-body {
    padding: 32px 16px 16px;
  }

  .ant-modal-close {
    color: $c-gray2;

    .ant-modal-close-x {
      width: 50px;
      height: 40px;
    }
  }

  .footer {
    margin-top: 24px;
  }

  .supporting--msg {
    text-align: center;

    img {
      height: 64px;
      margin-bottom: 8px;
    }

    h3 {
      font-size: clamp(20px, 3vw, 24px);
      color: $c-white;
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .supporting--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
    padding-bottom: 8px;

    .custom--share {
      margin-top: 4px;

      button {
        display: flex;
        width: 90px;
        height: 45px;

        border: 1px solid #0975a0 !important;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: #01acee !important;

        .custom--title {
          margin-left: 8px !important;
        }
      }
    }
  }
}

.custom--mini--modal {
  .ant-modal-body {
    padding: 24px 0 0;
  }

  .ant-modal-content {
    background-color: $c-dark-2;
    border-radius: 8px;
  }

  .ant-modal-close {
    color: $c-gray;

    .ant-modal-close-x {
      width: 50px;
      height: 40px;
    }
  }

  .footer {
    margin-top: 24px;
  }
}

.options--modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    text-align: center;
  }

  .options__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 1rem;

    .option__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 2rem;
      border-radius: 8px;
      border: 1px solid $c-purple2;
      background-color: $c-black;
      width: 200px;
      height: 180px;
    }

    .selected {
      background-color: $c-main;
    }

    img {
      height: 50px;
      width: 50px;
    }
  }
}


// Modal Float
.modal__float {
  background-color: #fff;
  width: max-content;
  min-width: 480px;
  min-height: 100px;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: -500%;
  margin: auto;
  z-index: 9999;
  transition: 0.5s;
  height: fit-content;
  overflow-x: hidden;
  padding: 16px;
  border-radius: 8px;


  &.show {
    right: 0;
  }

  .head {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    h3 {
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 600;
      color: $c-text;
    }

    .copy {
      color: $c-main;
      background-color: $c-background;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: clamp(14px, 3vw, 16px);
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }

  .wrapper {
    background: $c-background;
    border-radius: 8px;
    padding: 0 16px;

    code {
      white-space: pre;
    }
  }
}



.modal--overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 27, 36, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  &.active {
    opacity: 1;
    visibility: visible;
  }


}