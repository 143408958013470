.heading__area {
  h2 {
    margin-bottom: 0;
    font-size: clamp(20px, 4vw, 24px);
    font-weight: 600;
    color: $c-main;
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      background-color: rgba($c-second, .4);
      height: 10px;
      width: 68px;
      z-index: -1;

    }
  }
}


.title--h3 {
  font-size: clamp(14px, 3vw, 14px);
  font-weight: 600;
  color: $c-text;
}