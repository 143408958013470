.loading__area {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    padding: 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
  }

  .ant-spin-text {
    color: $c-main;
  }
}

.ant-spin-dot-item {
  background-color: $c-second;
}

.error__area {
  // height: 100%;
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  .content {
    text-align: center;
    span {
      font-size: clamp(16px, 3vw, 20px);
      font-weight: 600;
      color: $c-danger;
    }
    p {
      margin-top: 8px;
      font-size: clamp(12px, 3vw, 16px);
      font-weight: 400;
    }
  }
}
