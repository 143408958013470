.onboarding__area {
  background-color: $c-white;
  display: flex;
  // grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 32px;
  height: 100%;
  position: relative;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 8px;
  }

  .shape {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &:has(.onboarding__area--sidebar) {
    .onboarding__area--sidebar {
      background: red;
      width: 24%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &--sidebar {
    background: $c-background;
    padding: 24px;
    position: relative;


    @media (max-width: 1024px) {
      background: $c-white;
      padding: 16px;
    }

    .head {
      margin-bottom: 48px;

      @media (max-width: 1024px) {
        margin-bottom: 0;
      }

      img {
        height: 30px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: 1024px) {
        display: none;
      }


      h2 {
        font-size: clamp(16px, 3vw, 24px);
        font-weight: 600;
        color: $c-main;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 8px 0;
          font-size: clamp(14px, 3vw, 16px);
          font-weight: 400;
          color: $c-text;
          display: flex;
          align-items: center;
          gap: 8px
        }
      }
    }

    .illustration {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 16px;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &--content {
    padding: 32px;
    height: 100vh;
    overflow-x: auto;
    flex-grow: 1;

    @media (max-width: 1024px) {
      padding: 16px;
    }

    .head {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
    }

    .form {
      margin: auto;
      // margin-top: 10%;
      width: 42%;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1199px) {
        width: 50%;
      }

      @media (max-width: 1024px) {
        width: 60%;
      }

      @media (max-width: 991px) {
        width: 80%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }


      .title {
        font-size: clamp(24px, 3vw, 32px);
        font-weight: 600;
        color: $c-main;
        margin-bottom: 16px;

      }

      .subtitle {
        font-size: clamp(12px, 3vw, 14px);
        line-height: clamp(18px, 3vw, 20px);
        color: $c-gray;
        margin-bottom: 24px;

        span {
          color: $c-main;
        }
      }

      .feild--item {
        .label {
          font-size: clamp(12px, 3vw, 14px);
          color: $c-gray;
        }
      }
    }
  }
}


.agreement {
  background-color: $c-white;
  margin-top: 16px;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid $c-gray-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: clamp(12px, 3vw, 14px);
  line-height: 20px;

  a {
    color: $c-second;
    text-decoration: none;
  }
}

.send--email {
  background-color: $c-white;
  border: 1px solid $c-gray-light;
  border-radius: 4px;
  padding: 24px 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;

  h3 {
    font-size: clamp(14px, 3vw, 18px);
    font-weight: 500;
    color: $c-main;
  }

  p {
    font-size: clamp(12px, 3vw, 14px);
    line-height: clamp(18px, 3vw, 20px);
    font-weight: 400;
    color: $c-text;
  }
}


.back__arrow {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  cursor: pointer;

  span {
    color: $c-text;
  }
}