.balances--popover {
  background-color: $c-third;
  padding-block: 1rem;
  color: white;
  font-size: $font16;
  padding: 16px;
  p {
    font-size: $font20;
    font-weight: 600;
    text-align: center;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    min-width: 200px;

    .entry {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px 0;
      .amount {
        font-weight: 600;
      }
    }
  }
}

.ant-popover-inner {
  padding: 0;
  border-radius: 8px;
}
.ant-popover-inner-content {
  padding: 0;
  border-radius: 8px;
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #201f57;
}
