.key__wrapper {
  background-color: $c-background;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  // grid-template-columns: repeat(5, minmax(auto, 1fr));
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  &--item {
    color: $c-text;
    flex-grow: 1;
  }

  .action {
    cursor: pointer;
  }

  .key--blur {
    position: relative;
    cursor: pointer;

    .text {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      background-color: rgba($c-white, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        background-color: $c-white;
        width: max-content;
        padding: 8px 8px;
        font-size: 12px;
        border: 1px solid rgba($c-gray, 0.4);
        border-radius: 4px;
      }
    }
  }
}

.log__wrapper {
  background-color: rgba($c-white, 1);
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 8px;
  border-left: 4px solid transparent;
  transition: all ease-in 0.4s;
  cursor: pointer;

  &--head {
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    align-items: center;
    gap: 16px;
  }

  &--content {
    padding: 32px 0 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    color: $c-text;
    font-size: 14px;
  }

  &--item {
    color: $c-text;

    &.status {
      background-color: rgba($c-second, 0.1);
      color: $c-second;
      border-radius: 50px;
      width: max-content;
      padding: 4px 10px;
    }

    &.module {
      background-color: rgba($c-forth, 0.1);
      color: $c-main;
      border-radius: 50px;
      width: max-content;
      padding: 4px 10px;
    }
  }

  &:hover,
  &.active {
    border-left: 4px solid $c-second;
    transition: all ease-in 0.4s;
  }
}

.description__wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all ease-in 0.4s;
  cursor: pointer;

  &--item {
    color: $c-text;

    &:first-child {
      min-width: 100px;
    }

    &:last-child {
      color: $c-main;
    }
  }
}

.timeline__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &--item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &--line {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .head {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .copy {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
    }
    span {
      height: 32px;
      width: auto;
      padding: 0 8px;
      border-radius: 50px;
      color: rgba($c-second, 1);
      background-color: rgba($c-second, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $c-text;
    }

    pre {
      overflow: auto;
      padding: 16px;
      background-color: $c-background;
      border-radius: 2px;
    }
  }
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}
