.grid__col--4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  }
}

.grid__col--3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.grid__col--2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.section--row {
  padding: 32px 0;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.gap-8 {
  gap: 16px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.flex-direction--col {
  flex-direction: column;
}

.align--center {
  align-items: center;
}

.justify--end {
  justify-content: flex-end;
}

.justify--between {
  justify-content: space-between;
}

.justify--start {
  justify-content: flex-start;
}

.justify--center {
  justify-content: center;
}

.flex--column {
  flex-direction: column !important;
}