.sidebar__area {
  min-height: 100%;
  background-color: #fff;
  border-right: 1px dashed rgba($c-gray, 0.5);
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 0.5s;
  height: 100vh;
  // position: relative;
  overflow-x: hidden;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  display: none;

  @media (max-width: 991px) {
    // position: fixed;
    display: flex;

    left: -100%;
  }

  @media (max-width: 767px) {
    // width: 80%;
  }

  @media (max-width: 600px) {
    // width: 95%;
  }

  &.show {
    left: 0;
  }

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 32px;
      width: auto;
    }
  }

  &--body {}

  &--menu {
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    list-style: none;
    gap: 16px;

    li {
      width: 100%;

      a {
        text-decoration: none;

        .menu--item {
          height: 50px;
          width: 100%;
          background-color: rgba($c-gray, 0.04);
          font-size: clamp(14px, 3vw, 16px);
          padding: 0 16px;
          color: $c-text;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
          border-radius: 8px;
          gap: 8px;

          img {
            height: 20px;
            width: 20px;
            opacity: 0.5;
          }

          &:hover {
            background-color: $c-orange-light;
            color: $c-bitcion;

            img {
              opacity: 1;
            }
          }
        }
      }
    }

    .active {
      .menu--item {
        background-color: $c-orange-light;
        color: $c-bitcion;

        img {
          opacity: 1;
        }
      }
    }
  }

  &--bottom {}
}

.sidebar--overlay {
  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 27, 36, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 99;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.drawer__profile {
  .ant-drawer-body {
    padding: 16px;
  }

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .edit--btn {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($c-gray, 0.08);
      cursor: pointer;
      border-radius: 4px;
      color: $c-bitcion;
    }
  }

  &--body {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 16px;
      background-color: rgba($c-second, 0.04);
      border: 1px solid rgba($c-second, 0.4);
      margin-bottom: 8px;
      border-radius: 4px;

      span {
        color: $c-black;
        font-size: clamp(14px, 3vw, 14px);
        font-weight: 500;

        &:last-child {
          font-weight: 700;
          font-size: clamp(14px, 3vw, 16px);
          color: $c-main;
        }
      }
    }
  }
}