.custom__drawer {
  .wrapper {
    width: 60%;
    margin: 0 auto;
    margin-top: 24px;

    @media (max-width: 1099px) {
      width: 80%;
    }

    @media (max-width: 991px) {
      width: 90%;
    }
  }

  &--head {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;

    h3 {
      color: $c-main;
      font-size: clamp(18px, 3vw, 24px);
      line-height: clamp(24px, 3vw, 32px);
    }

    p {
      color: $c-text;
    }
  }
}

.ant-drawer {
  .ant-drawer-body {
    padding: 0 24px;
  }

  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-header-title {
    flex-direction: column;
    align-items: flex-start;

    .ant-drawer-title {
      font-size: clamp(18px, 3vw, 24px) !important;
    }
  }
}
