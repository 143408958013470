// New Select
.select__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;

  &.--error {
    .label__area {
      color: #f26262 !important;
    }

    .ant-select-selector {
      background: rgba(242, 98, 98, 0.1) !important;
    }

    .ant-select-selection-placeholder {
      color: #f26262 !important;
    }
  }

  &--field {
    width: 100%;
    position: relative;
  }

  & .ant-select {
    width: 100%;
    border-radius: 6px;
  }

  & .ant-select:not(.ant-select-customize-input) {
    & .ant-select-selector {
      height: 50px;
      background-color: rgba($c-white, 1);
      border: 1px solid rgba($c-gray-light, 1);
      border-radius: 6px;
      color: $c-text;
      font-weight: 700;
      font-size: 16;
      padding: 0 16px;
      display: flex;
      align-items: center;


    }

    &:hover .ant-select-selector {
      border-color: $c-second !important;
    }
  }

  & .ant-select-selector {
    height: 50px;
    background-color: rgba($c-background, .2);
    border: 1px solid rgba($c-gray, 0.2);

    &:focus {
      box-shadow: none;
    }

    &:focus,
    &:hover {
      border: 1px solid $c-black;
    }

    & .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & .ant-select-selection-overflow-item {
    & .ant-select-selection-item {
      background: rgba($c-second, 0.08) !important;
      border: 1px solid rgba($c-second, 0.1) !important;
      color: $c-second;
      font-size: 18px;

      .ant-select-selection-item-remove {
        color: $c-second;
      }
    }
  }
}

.select--wrapper {
  & .ant-select {
    width: 100%;
  }
}

//
.select--group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 991px) {
    width: 100%;
    padding-top: 24px;
  }

  label {
    padding-left: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    color: $c-gray;
  }

  &--content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 991px) {
      width: 100%;
    }

    .select {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $c-gray-light;
      background-color: $c-background;
      padding: 12px 8px;
      border-radius: 8px;
      min-width: 300px;
      width: 100%;
      cursor: pointer;

      .left {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-size: 16px;
        font-weight: 600;
        color: $c-text;

        img {
          height: 20px;
          margin-right: 8px;
        }

        span {
          margin-bottom: 0;
        }
      }

      svg {
        color: $c-gray;
      }
    }

    .balance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 8px;
      padding-left: 24px;
      padding-right: 24px;
      width: 100%;

      span {
        color: $c-text;
      }
    }
  }
}

.switch--area {
  cursor: pointer;
  height: 40px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $c-forth;
}

.custom--select {
  background-color: transparent;
  display: flex;
  align-items: center;
}

.market--select {
  display: flex;
  align-items: center;
  color: $c-text;
  cursor: pointer;

  .ant-select-selector {
    width: "100%";
    padding: 0 16px;
    border: 1px solid $c-gray-light !important;
    background-color: $c-background !important;
    width: 100% !important;
    height: 50px !important;
    border-radius: 8px !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: clamp(16px, 3vw, 14px);
      font-weight: 600;

      img {
        height: 24px;
        margin-right: 16px;
      }

      span {
        font-size: clamp(16px, 3vw, 14px);
        font-weight: 600;
      }
    }

    &:after {}
  }

  @media (max-width: 991px) {
    margin-top: 24px;
  }

  &:hover .ant-select-selector {
    border: 1px solid $c-forth;
    background-color: $c-white;
  }
}

.ant-select-single {
  .ant-select-selection-search-input {
    height: 50px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 45px !important;
  }
}

.ant-select-dropdown {
  background-color: $c-white !important;
  border: 1px solid $c-gray-light !important;

  .ant-select-item-option {
    background-color: transparent;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    color: $c-text;
    padding: 8px;

    .select--option {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    img {
      height: 28px;
      width: 28px;
      border-radius: 4px;
    }

    span {
      font-size: clamp(16px, 3vw, 18px);
      font-weight: 400;
    }
  }

  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: $c-orange-light !important;
    // border: 1px solid $c-forth !important;
    color: $c-text;
  }
}